<template>
    <div class="animated fadeIn">
        <b-card>
            <template v-slot:header>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="w-100 d-flex justify-content-end">
                        <b-button variant="primary" v-b-modal="'modal-form'">
                            <i class="fa fa-plus"></i> Tambah User
                        </b-button>
                    </div>
                </div>
            </template>
            <ManageUser />
        </b-card>
        <!-- START MODAL CREATE -->
        <b-modal id="modal-form" @hidden="onHideModal" size="lg" title="Form User" ref="my-modal" no-close-on-backdrop
            no-close-on-esc hide-footer>
            <b-form @submit.prevent="onSubmit">
                <div class="row">
                    <div class="col-lg-6">
                        <b-form-group class="required" label="Pilih Divisi">
                            <b-form-select :class="$v.form.devisi_id.$error ? 'is-invalid' : ''" name="id_devisi"
                                v-model="form.devisi_id" :options="optionDivisi"></b-form-select>
                            <small v-if="$v.form.devisi_id.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-6">
                        <b-form-group class="required" label="Pilih Role">
                            <b-form-select :class="$v.form.role_id.$error ? 'is-invalid' : ''" name="role_id"
                                v-model="form.role_id" :options="optionRoles"></b-form-select>
                            <small v-if="$v.form.role_id.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="required" label="Nama">
                            <b-form-input :class="$v.form.name.$error ? 'is-invalid' : ''" class="form-control"
                                v-model.trim="form.name" type="text" placeholder="nama lengkap"></b-form-input>
                            <small v-if="$v.form.name.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="required" label="Nama Pengguna">
                            <b-form-input :class="$v.form.username.$error ? 'is-invalid' : ''" class="form-control"
                                v-model="form.username" type="text" placeholder="username"></b-form-input>
                            <small v-if="$v.form.username.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="optional" label="Alamat Email">
                            <b-form-input class="form-control" v-model="form.email" type="email"
                                placeholder="email"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="required" label="Kata Sandi">
                            <b-input-group>
                                <b-form-input :class="$v.form.password.$error ? 'is-invalid' : ''" v-model="form.password"
                                    :type="showPassword ? 'text' : 'password'" placeholder="kata sandi"></b-form-input>
                                <b-input-group-append>
                                    <button @click="changeTypePassword" type="button" class="btn btn-secondary">
                                        <i v-if="!showPassword" class="fa fa-eye"></i>
                                        <i v-else class="fa fa-eye-slash"></i>
                                    </button>
                                </b-input-group-append>
                            </b-input-group>
                            <small v-if="$v.form.password.$error" class="text-danger">
                                Minimum 4 karakter
                            </small>
                        </b-form-group>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button :disabled="isLoading" type="submit" class="btn btn-primary">Submit</button>
                </div>
            </b-form>
        </b-modal>
        <!-- END MODAL CREATE -->
    </div>
</template>


<script>
import ManageUser from '../../components/manageusers/ManageUser.vue';
import { required, minLength } from "/node_modules/vuelidate/lib/validators";
import { mapState } from 'vuex'
export default {
    components: { ManageUser },
    computed: {
        ...mapState("loading", ["isLoading"]),
    },
    data() {
        return {
            optionDivisi: [],
            optionRoles: [],
            showPassword: false,
            form: {
                name: null,
                username: null,
                email: null,
                role_id: null,
                devisi_id: null,
                password: 12345678,
            }
        }
    },

    methods: {
        async getDivisi() {
            const response = await this.$store.dispatch("getDivisi", "all")
            this.optionDivisi = [{ value: null, text: "Pilih Divisi", disabled: true }, ...response]
        },
        async getRole() {
            const response = await this.$store.dispatch("getRole")
            const options = response.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    value: item.id,
                    text: item.name,
                }
            })
            this.optionRoles = [{ value: null, text: "Pilih Role", disabled: true }, ...options]
        },
        changeDivisi(data) {
            this.form.devisi_id = data.value
        },
        changeTypePassword() {
            this.showPassword = !this.showPassword
        },
        async onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$error) {
                return
            }
            const response = await this.$store.dispatch("createUser", this.form)
            if (response.code === 200) {
                this.$helper.toastSucc(this, "Berhasil Membuat User Baru")
                this.$store.dispatch("getUsers")
                this.$bvModal.hide("modal-form");
            } else {
                this.$helper.toastErr(this, "Gagal Membuat User Baru")
            }
        },
        onHideModal() {
            this.form = {
                name: null,
                username: null,
                email: null,
                role_id: null,
                devisi_id: null,
                password: 12345678,
            }
        }
    },
    validations: {
        form: {
            devisi_id: {
                required,
            },
            role_id: {
                required,
            },
            name: {
                required,
            },
            username: {
                required,
            },
            password: {
                required,
                minLength: minLength(4)
            },
        },
    },
    mounted() {
        this.getRole()
        this.getDivisi()
    }
}
</script>
