<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-3">
                <b-form-group label="Nama">
                    <input @change="handleChangeFilter(filters.name, 'search')" v-model="filters.name" type="text"
                        class="form-control" placeholder="cari nama">
                </b-form-group>
            </div>
            <div class="col-md-3">
                <b-form-group label="Username">
                    <input @change="handleChangeFilter(filters.username, 'search')" v-model="filters.username" type="text"
                        class="form-control" placeholder="cari username">
                </b-form-group>
            </div>
            <div class="col-md-3">
                <b-form-group label="Status">
                    <b-form-select @change="handleChangeFilter(filters.is_active, 'is_active')" v-model="filters.is_active"
                        :options="optionsIsActive"></b-form-select>
                </b-form-group>
            </div>
            <div class="col-md-3">
                <b-form-group label="Role">
                    <b-form-select @change="handleChangeFilter(filters.role_id, 'role_id')" v-model="filters.role_id"
                        :options="optionRoles"></b-form-select>
                </b-form-group>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <button class="btn btn-primary" @click="handleFilterSearch">CARI</button>
                </div>
            </div>

        </div>
        <template v-if="userLists.users">
            <b-table :items="userLists.users" striped bordered :fields="fields" :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" sort-icon-left responsive="sm">
                <template v-slot:cell(is_active)="row">
                    <span v-if="row.item.is_active === 1" class="badge badge-success">aktif</span>
                    <span v-else class="badge badge-danger">non aktif</span>
                </template>
                <template v-slot:cell(aksi)="row">
                    <button @click="showModalEdit(row.item.id)" class="btn btn-warning btn-sm ml-1">
                        <i class="fa fa-pencil fa-fw"></i>
                    </button>
                </template>
            </b-table>
            <div class="d-flex justify-content-end">
                <b-pagination @input="onPageChange" v-model="userLists.pagination.currentPage"
                    :total-rows="userLists.pagination.rows" :per-page="userLists.pagination.per_page"></b-pagination>
            </div>
        </template>
        <!-- START MODAL EDIT -->
        <b-modal id="modal-edit" size="lg" title="Form Edit User" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-form @submit.prevent="handleSumbitEdit">
                <div class="row">
                    <div class="col-lg-6">
                        <b-form-group class="required" label="Pilih Role">
                            <b-form-select :class="$v.form.role_id.$error ? 'is-invalid' : ''" name="role_id"
                                v-model="form.role_id" :options="optionRoles"></b-form-select>
                            <small v-if="$v.form.role_id.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-6">
                        <b-form-group class="required" label="Pilih Divisi">
                            <b-form-select :class="$v.form.devisi_id.$error ? 'is-invalid' : ''" name="id_devisi"
                                v-model="form.devisi_id" :options="optionDivisi"></b-form-select>
                            <small v-if="$v.form.devisi_id.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="required" label="Nama">
                            <b-form-input :class="$v.form.name.$error ? 'is-invalid' : ''" class="form-control"
                                v-model.trim="form.name" type="text" placeholder="nama lengkap"></b-form-input>
                            <small v-if="$v.form.name.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="required" label="Nama Pengguna">
                            <b-form-input :class="$v.form.username.$error ? 'is-invalid' : ''" class="form-control"
                                v-model="form.username" type="text" placeholder="username"></b-form-input>
                            <small v-if="$v.form.username.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="optional" label="Alamat Email">
                            <b-form-input class="form-control" v-model="form.email" type="email"
                                placeholder="email"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-12">
                        <b-form-group class="required" label="Kata Sandi">
                            <b-input-group>
                                <b-form-input v-model="form.password" :type="showPassword ? 'text' : 'password'"
                                    placeholder="kata sandi"></b-form-input>
                                <b-input-group-append>
                                    <button @click="changeTypePassword" type="button" class="btn btn-secondary">
                                        <i v-if="!showPassword" class="fa fa-eye"></i>
                                        <i v-else class="fa fa-eye-slash"></i>
                                    </button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="col-lg-6">
                        <b-form-group class="required" label="Status">
                            <b-form-select :class="$v.form.is_active.$error ? 'is-invalid' : ''" name="is_active"
                                v-model="form.is_active" :options="optionsStatus"></b-form-select>
                            <small v-if="$v.form.is_active.$error" class="text-danger">
                                Kolom tidak boleh kosong
                            </small>
                        </b-form-group>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button :disabled="isLoading" type="submit" class="btn btn-primary">Submit</button>
                </div>
            </b-form>
        </b-modal>
        <!-- END MODAL EDIT -->
    </div>
</template>

<script>
import { required } from "/node_modules/vuelidate/lib/validators";
import { mapState } from 'vuex'

export default {
    data() {
        return {
            sortBy: "age",
            sortDesc: false,
            showPassword: false,
            fields: [
                { key: "name", sortable: true },
                { key: "username", sortable: true },
                { key: "email", sortable: true },
                { key: "role", sortable: false },
                { key: "is_active", label: "Status", sortable: false },
                {
                    key: "aksi",
                    label: "Aksi",
                    thClass: "nameOfTheClassThAction text-right",
                    tdClass: "nameOfTheClassTd text-right",
                },
            ],
            filters: {
                search: "",
                is_active: "",
                role_id: null,
            },
            optionDivisi: [],
            optionRoles: [],
            userId: null,
            optionsIsActive: [
                {
                    id: 1,
                    value: 0,
                    text: "Aktif",
                },
                {
                    id: 2,
                    value: 1,
                    text: "Non Aktif",
                },
            ],
            optionsStatus: [
                {
                    id: 1,
                    value: 1,
                    text: "Aktif",
                },
                {
                    id: 2,
                    value: 0,
                    text: "Non Aktif",
                },
            ],
            form: {
                name: null,
                username: null,
                email: null,
                role_id: null,
                devisi_id: null,
                is_active: "",
            }
        };
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        userLists() {
            return this.$store.getters.userLists;
        },
    },
    methods: {
        async handleChangeFilter(value, name) {
            this.filters = {
                // ...this.filters,
                sort: "DESC",
                [name]: value
            }
        },

        handleFilterSearch() {
            this.$store.dispatch("getUsers", {
                ...this.filters,
                sort: "DESC",
            });
            this.getUsers(this.$route.query.page || 1)
        },
        async getUsers(page = 1) {
            await this.$store.dispatch("getUsers", {
                ...this.filters,
                sort: "DESC",
                page: page
            });
        },
        async getDivisi() {
            const response = await this.$store.dispatch("getDivisi", "all")
            this.optionDivisi = [{ value: null, text: "Pilih Divisi", disabled: true }, ...response]
        },
        async getRole() {
            const response = await this.$store.dispatch("getRole")
            const options = response.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    value: item.id,
                    text: item.name,
                }
            })
            this.optionRoles = [{ value: null, text: "Pilih Role", disabled: true }, ...options]
        },
        changeDivisi(data) {
            this.form.devisi_id = data.value
        },
        onPageChange(e) {
            this.getUsers(e);
            this.$router.push({
                query: {
                    page: e
                }
            })
        },
        changeTypePassword() {
            this.showPassword = !this.showPassword
        },
        // MODAL EDIT
        showModalEdit: async function (id) {
            const data = await this.$store.dispatch("getDetailUser", id);
            this.form.name = data.name;
            this.form.username = data.username;
            this.form.email = data.email;
            this.form.role_id = data.role_id;
            this.form.devisi_id = data.devisi_id;
            this.form.is_active = data.is_active === 1 ? data.is_active : 0;
            this.userId = id;
            this.$bvModal.show("modal-edit");
        },
        handleSumbitEdit: async function () {
            this.$v.form.$touch();
            if (this.$v.form.$error) {
                return
            }
            const response = await this.$store.dispatch("updateUser", {
                data: this.form,
                id: this.userId
            })
            if (response.code === 200) {
                this.$helper.toastSucc(this, "Berhasil Update User")
                this.$bvModal.hide("modal-edit");
                this.$store.dispatch("getUsers")
                this.getUsers(this.$route.query.page)
            } else {
                this.$helper.toastErr(this, "Gagal Update User")
            }
        },
    },
    validations: {
        form: {
            devisi_id: {
                required,
            },
            role_id: {
                required,
            },
            name: {
                required,
            },
            username: {
                required,
            },
            is_active: {
                required,
            }
        },
    },
    mounted() {
        this.getUsers(this.$route.query.page || 1);
        this.getRole()
        this.getDivisi()
    }
}
</script>